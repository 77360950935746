import { Link } from "gatsby"
import React, { useContext } from "react"
import { StoreContext } from "../../context/store-context"
import LineItem from "./LineItem"
const cur = process.env.GATSBY_CURRENCY
const locale = process.env.GATSBY_LOCAL

const Cart = () => {
  const { checkout } = useContext(StoreContext)
  const handleCheckout = (checkout_locale) => {
    window.open(checkout.webUrl + checkout_locale)
  }

  const lineItems = checkout.lineItems.map((item) => (
    <LineItem key={item.id.toString()} item={item} />
  ))

  const getPrice = (price) =>
    Intl.NumberFormat(undefined, {
      currency: cur,
      minimumFractionDigits: 0,
      style: "decimal",
    }).format(parseFloat(price ? price : 0))

  return (
    <div className="cart-page">
      {lineItems}
      <p className="cart-total">
        Subtotal {getPrice(checkout.totalPrice.amount)} {cur}
      </p>

      <button
        className="cart-checkout-button"
        onClick={() => handleCheckout("&locale=en")}
        disabled={checkout.lineItems.length === 0}
      >
        Check out
      </button>
      {locale === "vi" ? (
        <button
          style={{ marginLeft: 10, marginTop: 10 }}
          className="cart-checkout-button"
          onClick={() => handleCheckout("&locale=vi")}
          disabled={checkout.lineItems.length === 0}
        >
          Check out (Tiếng Việt)
        </button>
      ) : null}
    </div>
  )
}

export default Cart
