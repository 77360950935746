import { Container } from "@mui/material"
import React from "react"
import Cart from "../components/Cart"
import { Layout } from "../components/layout"

const CartPage = () => (
  <Layout>
    <Container maxWidth="md">
      <h1 style={{ textAlign: "center", marginBottom: 30 }}>Cart</h1>
      <Cart />
    </Container>
  </Layout>
)

export default CartPage
