import React, { useContext } from "react"
import { StoreContext } from "../../../context/store-context"
import { Grid } from "@mui/material"

const LineItem = (props) => {
  const { item } = props

  const price = {
    amount: item.variant.price,
    currency: item.variant.priceV2.currencyCode,
  }
  const { removeLineItem, checkout } = useContext(StoreContext)
  const variantImage = item.variant.image ? (
    <img
      src={item.variant.image.src}
      alt={`${item.title} product shot`}
      height="80px"
    />
  ) : null

  const selectedOptions = item.variant.selectedOptions
    ? item.variant.selectedOptions.map((option, index) =>
        option.name !== "Title" && option.name !== "Size Chart" ? (
          <div key={index}>
            {option.name}: {option.value}
          </div>
        ) : null
      )
    : null

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id)
  }

  return (
    <Grid container spacing={2} className="cart-item">
      <Grid item xs={3} md={2}>
        {variantImage}
      </Grid>
      <Grid className="cart-item-content" item xs={4} md={6}>
        <div className="cart-item-title">
          {item.title}
          {item.variant.title === !"Default Title" ? item.variant.title : ""}
        </div>
        {selectedOptions}
        {price.amount.amount} {price.currency} x {item.quantity}
      </Grid>
      <Grid item xs={3} md={2}>
        <div>
          <button className="cart-remove" onClick={handleRemove}>
            Remove
          </button>
        </div>
      </Grid>
      <Grid item xs={2} md={2}>
        {price.amount.amount * item.quantity} {price.currency}
      </Grid>
    </Grid>
  )
}

export default LineItem
